/** @jsx jsx */
import { jsx } from 'theme-ui';
import ProtectedRoute from 'src/components/composite/ProtectedRoute';
import { HistoryChart } from '@charts';
import { ChartContextWrapper } from 'src/components/composite/chart-generator/wrappers/ChartContextWrapper';

export default function () {
  return (
    <ChartContextWrapper>
      <ProtectedRoute path="/" component={HistoryChart} />
    </ChartContextWrapper>
  );
}
